<template>
    <div class="regi-rg">
        <img src="../assets/photo/login-tp.png" alt="">
        <h1>{{$t('BuyCryptocurrencyQuickly')}}</h1>
        <p>{{$t('startJoin')}}</p>
    </div>
</template>

<script>
    export default {
        name: 'loginRight'
    }
</script>